/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import {
  CardElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import {
  Input, Button, H1, H3, H2,
} from '@allthingswww/client-act-shared';
import styled from 'styled-components';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { renderEmail } from 'react-html-email';

import { format } from 'prettier';
import QRCode from '../components/QrCode';
import Layout from '../components/Layout';
import fetchPublic from '../lib/fetchPublic';
import { fetchWAuth } from '../lib';
import { getEventCheckoutData } from '../redux/eventCheckout';
import { getMyInfo } from '../redux/member';
import { updateEvent, getEventByID } from '../redux/event';
import { createPurchase } from '../redux/purchase';

const Currency = new Intl.NumberFormat('en-us', { style: 'currency', currency: 'USD' });

const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#000',
      color: '#000',
      fontWeight: 500,
      height: '2rem',
      border: '2px solid #777',
      marginBottom: '2rem',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#fce883',
      },
      '::placeholder': {
        color: '#929292',
      },
    },
    invalid: {
      iconColor: '#eb6565',
      color: '#eb6565',
    },
  },
};

const Div = styled.div`
  margin-bottom: 1rem;
`;

const CardField = ({ onChange }) => (
  <div>
    <CardElement options={CARD_OPTIONS} onChange={onChange} />
  </div>
);

const ErrorMessage = ({ children }) => (
  <Div>
    <svg width="16" height="16" viewBox="0 0 17 17">
      <path
        fill="#000"
        d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
      />
      <path
        fill="red"
        d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
      />
    </svg>
    {children}
  </Div>
);

const FieldSet = styled.fieldset`
  margin-bottom: 2rem;
  border: 2px solid #777;
  line-height: 20px;
  padding: 12px 6px;
  margin-bottom: 2rem;
  margin-top: 0.75rem;
`;

const Row = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: row wrap;
`;

const Flex = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
`;

const StyledH2 = styled(H2)`
  margin: 0;
`;

const DisplaySummaryOfEventsToBePaid = ({ name, itemsToBePaid = {}, total = 0 }) => (
  <div>
    <div>{name}</div>
    {Object.keys(itemsToBePaid).map((val, k) => (
      // eslint-disable-next-line react/no-array-index-key
      <Row key={k}>
        <H3>
          {val}
          {' '}
          :
          {' '}
          {Currency.format(itemsToBePaid[val] / 100)}
        </H3>
      </Row>
    ))}
    <Row>
      <H3>
        Total :
        {' '}
        {Currency.format(total / 100)}
      </H3>
    </Row>
  </div>
);

const DisplayDetailsOfEventsToBePaid = ({ eventCheckoutData = {} }) => {
  if (_.isEmpty(eventCheckoutData)) {
    return (<p>No checkout details</p>);
  }
  const { amountToBePaidDetails } = eventCheckoutData;
  return (
    <H3>
      ===========
      {
        amountToBePaidDetails.map(({ fullName, eventItems }) => (
          <Row>
            <div>
              Items for
{' '}
              {fullName}
:
{' '}
            </div>
            <div>
              {
                Object.keys(eventItems).map((val, key) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <H3 key={key}>
                    {' '}
                    {eventItems[val].values[0]}
                    {' '}
                    {val}
                  </H3>
                ))
              }
            </div>
          </Row>
        ))
      }
    </H3>
  );
};

const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [paymentSuccess, setPaymenySuccess] = useState(false);
  const [cardComplete, setCardComplete] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [stringifiedPaymentMethod, setStringifiedPaymentMethod] = useState('');
  const [billingDetails, setBillingDetails] = useState({
    email: '',
    phone: '',
    name: '',
  });
  const dispatch = useDispatch();
  const eventCheckoutData = useSelector(getEventCheckoutData);
  const myInfo = useSelector(getMyInfo);
  const eventDetails = useSelector(getEventByID(props['*']));

  useEffect(() => {
    if (paymentMethod) {
      setStringifiedPaymentMethod(JSON.stringify(paymentMethod));
      setPaymenySuccess(true);
    }
  }, [paymentMethod]);

  useEffect(() => {
    if (stringifiedPaymentMethod !== '') {
      // eslint-disable-next-line no-use-before-define
      sendReceiptByEmail({ destinationEmailAddress: paymentMethod.billing_details.email });
    }
  }, [stringifiedPaymentMethod]);

  if (typeof window !== 'undefined' && _.isEmpty(eventCheckoutData)) {
    navigate(`/register-attendees/${props['*']}`);
  }

  if (_.isEmpty(eventDetails)) {
    return (
      <Layout
        title="No event details"
        subtitle=""
        condensed
      >
        <div>No details about this event</div>
      </Layout>

    );
  }
  const {
    _id,
    maxNumberOfAttendees,
    remainingSeats,
    eventItems,
    name,
    date,
  } = eventDetails[0];

  const updateNumberOfAttendees = (numberOfAttendeesRegistered) => {
    let updatedRemainingSeats = null;
    if (typeof remainingSeats !== 'number') {
      updatedRemainingSeats = maxNumberOfAttendees - numberOfAttendeesRegistered;
    } else {
      updatedRemainingSeats = remainingSeats - numberOfAttendeesRegistered;
    }
    dispatch(updateEvent({
      id: _id,
      data: {
        remainingSeats: updatedRemainingSeats,
      },
      action: 'updateRemainingSeats',
    }));
  };
  // eslint-disable-next-line no-underscore-dangle
  let userId = myInfo._id;
  let total = 0;

  const registerPurchase = (data) => {
    const formattedData = {
      data: {},
    };
    formattedData.data.additionalTextField = JSON.stringify(eventCheckoutData);

    if (typeof eventItems !== 'undefined') {
      const allEventItems = JSON.parse(eventItems);
      // eslint-disable-next-line no-underscore-dangle
      const itemIds = allEventItems.map((eventItem) => eventItem._id);
      formattedData.data.itemIds = { connect: [...itemIds] };
    }
    if (typeof userId !== 'undefined') {
      formattedData.data.member = { connect: userId };
    }
    if (typeof props['*'] !== 'undefined') {
      formattedData.data.event = { connect: props['*'] };
    }
    if (!_.isEmpty(data)) {
      const { amount, status } = data.paymentIntent;
      formattedData.data.transaction = {
        create: {
          transactionData: JSON.stringify(data),
          dateTime: new Date().toUTCString(),
          success: (status === 'succeeded'),
          amount,
        },
      };
    }

    dispatch(createPurchase(formattedData));
  };

  // eslint-disable-next-line no-return-assign
  _.forOwn(eventCheckoutData.allItemsToBePaid, (value) => (total += value));

  const sendReceiptByEmail = ({ destinationEmailAddress = '' }) => {
    if (typeof userId === 'undefined') {
      userId = destinationEmailAddress;
    }

    const url = btoa(stringifiedPaymentMethod);
    const htmlMessage = renderEmail(
      <div>
        <DisplaySummaryOfEventsToBePaid
          eventCheckoutData={eventCheckoutData}
          itemsToBePaid={eventCheckoutData.allItemsToBePaid}
          total={total}
        />
        <DisplayDetailsOfEventsToBePaid eventCheckoutData={eventCheckoutData} />
      </div>,
    );
    const htmlEventDetails = renderEmail(
      <div>
        <br />
        <div>
          <strong>Event Title: </strong>
          {name}
        </div>
        <br />
        <div>
          <strong>Event Title: </strong>
          {date}
        </div>
        <br />
      </div>,
    );
    fetchPublic('/.netlify/functions/send-mail', {
      body: JSON.stringify({
        to: destinationEmailAddress,
        subject: 'Payment Successful!',
        text: `
          `,
        html: `
          Hello ${billingDetails.name}, you have been successfully registered for an upcoming ACT event! You can find event details below, as well as a link to your unique QR Code. We look forward to seeing you there.
        ${htmlEventDetails}
          ${htmlMessage}
            <a href="${props.location.origin}/qrcode/${url}" target="_blank">Click here to view QR Code</a>
          `,
      }),
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    if (error) {
      elements.getElement('card').focus();
      return;
    }

    if (cardComplete) {
      setProcessing(true);
    }

    const { clientSecret } = await fetchPublic('/.netlify/functions/payment-intent', { body: JSON.stringify({ amount: total }) });

    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: billingDetails,
    });

    setProcessing(false);

    if (payload.error) {
      setError(payload.error);
    } else {
      const response = await stripe.confirmCardPayment(clientSecret, {
        payment_method: payload.paymentMethod.id,
      });
      if (!_.isEmpty(response.error)) {
        setError(response.error);
        return;
      }
      registerPurchase(response);
      setPaymentMethod(payload.paymentMethod);
      updateNumberOfAttendees(eventCheckoutData.attendees.length || 0);
    }
  };
  if (paymentSuccess) {
    navigate(`/confirmPayment/${props['*']}`, {
      state: {
        stringifiedPaymentMethod,
        eventCheckoutData,
        total,
      },
    });
  }
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <fieldset>
          <Input
            label="Name"
            id="name"
            type="text"
            placeholder="Jane Doe"
            required
            autoComplete="name"
            value={billingDetails.name}
            handleChange={(e) => {
              setBillingDetails({ ...billingDetails, name: e.target.value });
            }}
          />
          <Input
            label="Email"
            id="email"
            type="email"
            placeholder="janedoe@gmail.com"
            required
            autoComplete="email"
            value={billingDetails.email}
            handleChange={(e) => {
              setBillingDetails({ ...billingDetails, email: e.target.value });
            }}
          />
          <Input
            label="Phone"
            id="phone"
            type="tel"
            placeholder="(941) 555-0123"
            required
            autoComplete="tel"
            value={billingDetails.phone}
            handleChange={(e) => {
              setBillingDetails({ ...billingDetails, phone: e.target.value });
            }}
          />
        </fieldset>
        <FieldSet>
          <CardField
            onChange={(e) => {
              setError(e.error);
              setCardComplete(e.complete);
            }}
          />
        </FieldSet>
        {error && <ErrorMessage>{error.message}</ErrorMessage>}
        <Flex>
          <Button.Button variant href="" onClick={() => navigate(-1)}>Back</Button.Button>
          <Button.Primary disabled={!stripe || error}>
            {processing && (<span>processing...</span>)}
            {(!processing) && <span>Pay</span>}
          </Button.Primary>
        </Flex>
      </form>
      <StyledH2>Checkout details</StyledH2>
      <DisplaySummaryOfEventsToBePaid
        itemsToBePaid={eventCheckoutData.allItemsToBePaid}
        total={total}
      />
      <DisplayDetailsOfEventsToBePaid
        eventCheckoutData={eventCheckoutData}
      />
    </div>

  );
};

const CheckoutWrapper = (props) => (
  <Layout title="Event" subtitle="" condensed>
    <CheckoutForm {...props} />
  </Layout>
);

export default CheckoutWrapper;
